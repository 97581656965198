





























import { ImageEntry, Market } from '@ht-lib/accounts-models'
import Vue from 'vue'
import { Component, Ref, Watch } from 'vue-property-decorator'
import Search from '../components/Search.vue'
import DownloadRequestTable from '../components/DownloadRequestTable.vue'
import DownloadRequestModal from '../components/DownloadRequestModal.vue'
import { ImagesPreview } from '@ht-vue/image-transfer'
import { LongRefSelectMap } from '@ht-lib/image-util'
import { searchImages, SearchType } from '../ts/search'

@Component({
  name: 'Download',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  components: { Search, DownloadRequestTable, DownloadRequestModal, ImagesPreview }
})
export default class Download extends Vue {
  @Ref() downloadRequestModal: DownloadRequestModal
  images: ImageEntry[] = []
  selectedImages: LongRefSelectMap = {}

  searchTerm = ''
  market: Market = 'S'
  searchType: SearchType = SearchType.JOB

  get finalImages () {
    const some = Object.values(this.selectedImages).some(x => x)
    if (!some) return this.images
    return this.images.filter(x => this.selectedImages[x.longRef])
  }

  created () {
    this.queryChanged()
  }

  clear () {
    this.$router.push({ query: {} })
  }

  @Watch('$route.query')
  queryChanged () {
    console.log('Query changed')
    const query = this.$route.query
    this.searchTerm = query.term as string
    this.searchType = query.type as SearchType
    this.market = query.market as Market

    if (!this.searchTerm) {
      return this.clear()
    }
    this.getImages()
  }

  async getImages () {
    const images = await searchImages(this.market, this.searchTerm, this.searchType)
    if (!images?.length) {
      this.images = []
      return
    }
    console.log('New Images. Length: ', images?.length, 'search term:', this.searchTerm)
    this.images = images
  }

  onSearch (term: string, market: Market, type: SearchType) {
    this.$router.push({
      query: {
        term,
        market,
        type
      }
    })
    this.searchTerm = term
    this.market = market
    this.searchType = type
    this.getImages()
  }

  openRequestModal () {
    this.downloadRequestModal.showModal({
      entries: this.finalImages,
      title: this.searchTerm,
      market: this.market,
      canUsePrep: true,
      addEditedFolder: false,
      contextId: this.searchTerm,
      onlyFlagged: false
    })
  }
}
